import request from './request'

// 获取设备列表
export const getevaluate = data => {
  return request({
    url: 'admin/v1/evaluate',
    params: data
  })
}
// 获取设备列表
export const POSTevaluateReply = data => {
  return request({
    url: 'admin/v1/evaluateReply',
    method: 'POST',
    data
  })
}
// 修改评论
export const POSTevaluateDeal = data => {
  return request({
    url: 'admin/v1/evaluateDeal',
    method: 'POST',
    data
  })
}
// 修改评论
export const GETcomplaint = data => {
  return request({
    url: 'admin/v1/complaint',
    params: data
  })
}
// 投诉处理
export const POSTcomplaintReply = data => {
  return request({
    url: 'admin/v1/complaintReply',
    method: 'POST',
    data
  })
}
// 投诉处理
export const GETcomplaintType = () => {
  return request({
    url: 'admin/v1/complaintType'
  })
}
