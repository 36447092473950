<template>
  <div>
    <div class="title">
      <div class="flex">
        <b class="marginRight">所属市场</b>
        <el-select
          v-model="search.market_id"
          clearable
          placeholder="请选择市场"
        >
          <el-option
            v-for="item in marketid"
            :key="item.id"
            :label="item.comp_name"
            :value="item.id"
            @click.native="sellerids(item.id)"
          >
          </el-option>
        </el-select>
      </div>
      <div class="flex">
        <b class="marginRight">所属商户</b>
        <el-select
          v-model="search.seller_id"
          clearable
          placeholder="请选择商户"
        >
          <el-option
            v-for="item in sellerid"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="flex">
        <b class="marginRight">投诉类型</b>
        <el-select
          v-model="search.complaint_types"
          clearable
          placeholder="请选择投诉类型"
        >
          <el-option
            v-for="item in complaintType"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="flex">
        <b class="marginRight">处理结果</b>
        <el-select
          v-model="search.complaint_status"
          clearable
          placeholder="请选择处理结果"
        >
          <el-option label="待处理" value="1"> </el-option>
          <el-option label="已解决" value="2"> </el-option>
          <el-option label="其他" value="3"> </el-option>
        </el-select>
      </div>
    </div>
    <div class="title" style="margin-top:20px">
      <div class="flex">
        <b class="marginRight">投诉时间</b>
        <el-date-picker
          v-model="datePicker"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
        >
        </el-date-picker>
      </div>
      <div class="flex">
        <b class="marginRight">处理时间</b>
        <el-date-picker
          v-model="timePicker"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
        >
        </el-date-picker>
      </div>
      <div class="flex">
        <b class="marginRight">搜索</b>
        <el-input
          v-model="search.name"
          style="width:217px"
          placeholder="输入关键字筛选"
          clearable
        ></el-input>
      </div>
      <div class="flex">
        <el-button @click="GET_complaint" type="primary">筛 选</el-button>
      </div>
    </div>
    <el-table
      :data="tableData.data"
      border
      :header-cell-style="{ textAlign: 'center' }"
      :cell-style="{ textAlign: 'center' }"
      style="width: 100%;margin-top:20px"
    >
      <el-table-column prop="complaint_num" label="投诉编号"> </el-table-column>
      <el-table-column prop="market_name" label="所在市场"> </el-table-column>
      <el-table-column prop="seller_name" label="被投诉商户" width="100">
      </el-table-column>
      <el-table-column prop="complaint_name" label="投诉人姓名">
      </el-table-column>
      <el-table-column prop="complaint_tel" label="投诉人电话">
      </el-table-column>
      <el-table-column label="投诉内容">
        <template slot-scope="scope">
          <div class="result">{{ scope.row.result }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="" label="投诉图片">
        <template slot-scope="scope">
          <div class="betten">
            <img
              v-for="(item, index) in scope.row.picture"
              :key="index"
              :src="item"
              @click="$refs.img.previewImage(item)"
              style="width:50px;height:40px"
              alt=""
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column label="投诉类型">
        <template slot-scope="scope">
          <el-tag type="success" effect="dark">
            {{ scope.row.complaint_title[0] }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="date" width="160" label="投诉时间">
      </el-table-column>
      <el-table-column label="处理结果">
        <template slot-scope="scope">
          <el-link
            :underline="false"
            v-if="scope.row.complaint_status * 1 === 1"
            type="primary"
            @click="dialog(scope.row)"
            >待处理</el-link
          >
          <el-link
            :underline="false"
            v-if="scope.row.complaint_status * 1 === 2"
            type="success"
            >已解决</el-link
          >
          <el-link
            :underline="false"
            v-if="scope.row.complaint_status * 1 === 3"
            type="warning"
            @click="dialog(scope.row)"
            >其他</el-link
          >
        </template>
      </el-table-column>
      <el-table-column prop="reply_date" label="解决时间" width="180">
      </el-table-column>
    </el-table>
    <div class="footers">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        current-page.sync="1"
        :page-sizes="[10, 20, 30]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.total"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="投诉处理"
      :visible.sync="centerDialogVisible"
      width="40%"
      center
      class="dialog"
    >
      <div class="list">
        <h3><span>所在市场：</span> {{ row.market_name }}</h3>
        <h3>
          <span>投诉编号：</span> <i>{{ row.complaint_num }}</i>
        </h3>
      </div>
      <div class="list">
        <h3><span>被投诉经营户：</span> {{ row.seller_name }}</h3>
        <h3>
          <span>经营户联系电话：</span> <i>{{ row.seller_tel }}</i>
        </h3>
      </div>
      <div class="list">
        <h3><span>投诉人：</span> {{ row.complaint_name }}</h3>
        <h3>
          <span>投诉人电话：</span> <i>{{ row.complaint_tel }}</i>
        </h3>
      </div>
      <div style="display: flex;align-items: center;margin-top: 15px;">
        <h1 class="h1">投诉内容</h1>
        <div style="margin-left:20px">
          <el-tag
            size="small"
            v-for="item in row.complaint_title"
            :key="item"
            type="warning"
            effect="dark"
            style="margin-right:10px"
          >
            {{ item }}
          </el-tag>
        </div>
      </div>
      <div class="dialog-footer">
        <div class="betten">
          <div>
            {{ row.result }}
          </div>
        </div>
        <img
          v-for="(item, index) in row.picture"
          :key="index"
          :src="item"
          alt=""
          style="width:120px;height:80px;margin-right:5px"
        />
      </div>
      <div style="margin-top:20px">
        <h3 style="display:inline-block;width:140px ">
          处理结果
        </h3>
        <el-select v-model="complaint_status" placeholder="请选择">
          <el-option label="待处理" value="1"> </el-option>
          <el-option label="已解决" value="2"> </el-option>
          <el-option label="其他" value="3"> </el-option>
        </el-select>
      </div>
      <el-input
        type="textarea"
        placeholder="请输入处理结果描述"
        v-model="textarea"
        resize="none"
        style="margin-top:10px;height:100px"
      >
      </el-input>
      <div style="text-align:right">
        <el-button @click="submit" type="primary">确 认</el-button>
      </div>
    </el-dialog>
    <DialogImg v-show="0" ref="img" />
  </div>
</template>

<script>
import { getmarket } from '../../../api/layout'
import { getSellerByMarketId } from '../../../api/productList'
import {
  GETcomplaint,
  POSTcomplaintReply,
  GETcomplaintType
} from '../../../api/evaluateList'
export default {
  name: 'evaluateList2',
  data () {
    return {
      marketid: '',
      sellerid: '',
      value: '', // 删除
      search: {
        page: '',
        limit: '',
        market_id: '',
        seller_id: '',
        complaint_types: '',
        complaint_status: '',
        date: '',
        reply_date: '',
        name: ''
      },
      datePicker: '',
      timePicker: '',
      row: '', // 弹框数据
      complaint_status: '',
      textarea: '',
      tableData: '', // 表格数据
      centerDialogVisible: false,
      complaintType: '' // 投诉类型列表
    }
  },
  watch: {
    datePicker (val) {
      if (val === null) {
        this.search.date = ''
      } else {
        this.search.date = val[0] + ',' + val[1]
      }
    },
    timePicker (val) {
      if (val === null) {
        this.search.reply_date = ''
      } else {
        this.search.reply_date = val[0] + ',' + val[1]
      }
    }
  },
  methods: {
    // 表格数据
    GET_complaint () {
      GETcomplaint(this.search).then(res => {
        this.tableData = res.data
      })
    },
    // 每一页的条数 10/条
    handleSizeChange (val) {
      this.search.limit = val
      this.GET_complaint()
    },
    // 当前的页码
    handleCurrentChange (val) {
      this.search.page = val
      this.GET_complaint()
    },
    //  市场
    get_market () {
      getmarket().then(res => {
        this.marketid = res.data
      })
    },
    // 商户
    sellerids (id) {
      getSellerByMarketId(id).then(res => {
        this.sellerid = res.data
      })
    },
    dialog (row) {
      this.centerDialogVisible = true
      this.row = row
    },
    submit () {
      POSTcomplaintReply({
        id: this.row.id,
        complaint_status: this.complaint_status,
        reply: this.textarea
      })
        .then(res => {
          this.$message.success(`${res.data}`)
          this.centerDialogVisible = false
          this.GET_complaint()
          this.row = ''
          this.complaint_status = ''
          this.textarea = ''
        })
        .catch(err => {
          this.$message.error(`${err.msg}`)
        })
    },
    mounted () {
      GETcomplaintType().then(res => {
        this.complaintType = res.data
      })
    }
  },
  mounted () {
    this.get_market()
    this.GET_complaint()
    this.mounted()
  }
}
</script>

<style lang="less" scoped>
.footers {
  text-align: right;
  margin-top: 10px;
}
.title {
  width: 90%;
  display: flex;
  justify-content: space-between;
}
.marginRight {
  display: inline-block;
  margin-right: 20px;
  width: 65px;
}
.result {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.flex {
  flex: 1;
}
.betten {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
/deep/ .el-dialog__body {
  padding: 5px 25px 30px;
}
.list {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  span {
    display: inline-block;
    width: 140px;
    text-align: left;
  }
  i {
    display: inline-block;
    width: 140px;
    font-style: normal;
  }
}
.h1 {
  font-size: 22px;
  color: #303133;
}
.dialog-footer {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  .betten {
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.dialog /deep/ .el-textarea__inner {
  height: 80px;
}
/deep/ .el-date-editor .el-range__icon {
  display: none;
}
/deep/ .el-range-editor.el-input__inner {
  width: 220px;
}
</style>
